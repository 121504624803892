import { IFilterSchema } from 'components/Filters/types/filterSchema.interface';
import { OwnerTinkoffOperationTypeEnum, ownerTinkoffOperationTypeToTitle } from 'modules/owners/types/type.enum';

export const filtersSchema: IFilterSchema[] = [
  {
    title: 'ID Владельца',
    name: 'ownerId',
    type: 'input-number',
  },
  {
    title: 'Сумма от',
    name: 'amountFrom',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Сумма до',
    name: 'amountTo',
    type: 'input-number',
    isAmountInput: true,
  },
  {
    title: 'Дата создания',
    name: 'creationDate',
    type: 'date-picker',
    rangeFields: ['creationDateFrom', 'creationDateTo'],
  },
  {
    title: 'Тип операции',
    name: 'type',
    type: 'select',
    options: Object.values(OwnerTinkoffOperationTypeEnum).map((value) => ({
      label: ownerTinkoffOperationTypeToTitle[value],
      value,
    })),
  },
];
