import React, { useEffect } from 'react';

import { Button, Table, App } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { fetchTinkoffOperations } from 'modules/owners/store/tinkoffOperations';
import { IFetchOwnerOperationsParams } from 'modules/owners/store/tinkoffOperations/interfaces/fetchOwnerOperationsParams.interface';

import { filtersSchema } from 'modules/owners/pages/tinkoffOperations/filters';
import { columns } from 'modules/owners/pages/tinkoffOperations/columns';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';

import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import instance from 'utils/axios';
import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';

export const TinkoffOperationsPage: React.FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchOwnerOperationsParams>();

  const { notification, message } = App.useApp();

  const {
    operations,
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.tinkoffOperations);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchOperations(memoFilters);
  }, []);

  function handleFiltersChange(filters: IFetchOwnerOperationsParams) {
    setMemoFilters(filters);
    fetchOperations(filters);
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetchOperations(memoFilters, page, perPage);
  }

  function fetchOperations(filters: IFetchOwnerOperationsParams, requestPage = page, requestPerPage = perPage) {
    dispatch(fetchTinkoffOperations({ ...filters, page: requestPage, perPage: requestPerPage }));
  }

  async function handleExportClick(filters: IFetchOwnerOperationsParams): Promise<void> {
    if (!filters.creationDateFrom || !filters.creationDateTo) {
      message.warning('Даты создания от и до обязательны при генерации отчета');

      return;
    }

    try {
      await instance.post('/owners/tinkoff-operations/export', sanitizeRequestParams(filters));

      notification.success({
        message: 'Отчет формируется и будет доступен во вкладке "Отчеты"',
        duration: 3,
      });
    } catch {
      message.error('Ошибка генерации отчета');
    }
  }

  return (
    <>
      <Filters
        headerTitle="Операции Тинькофф"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={handleFiltersChange}
        customButtons={(getFilters) => (
          <ProtectedComponent requiredPermissions={[permissions.tinkoff.export]}>
            <Button
              className="bg-green-600 text-white border-green-600 hover:bg-green-500"
              type="default"
              onClick={() => handleExportClick(getFilters())}
              icon={<DownloadOutlined />}
              style={{ color: 'white', border: 'none' }}
            >
              Скачать в xlsx
            </Button>
          </ProtectedComponent>
        )}
      />
      <Table
        className="big-table"
        columns={columns}
        dataSource={operations}
        pagination={false}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <Pagination
        customStyle="mt-2"
        pagination={pagination}
        isLoading={isLoading}
        onPaginationChange={handlePaginationChange}
      />
    </>
  );
};
