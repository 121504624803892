import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';
import instance from 'utils/axios';
import { IFetchOwnerOperationsParams } from 'modules/owners/store/tinkoffOperations/interfaces/fetchOwnerOperationsParams.interface';
import { ITinkoffOperationsState } from 'modules/owners/store/tinkoffOperations/interfaces/tinkoffOperationsState.interface';
import { RequestParamsWithPagination } from 'types/requestParamsWithPagination.interface';

const createSlice = buildCreateSlice({ creators: { asyncThunk: asyncThunkCreator } });

const tinkoffOperationsSlice = createSlice({
  name: 'tinkoffOperations',
  initialState: {
    operations: [],
    pagination: {
      page: 1,
      pages: 0,
      perPage: 20,
      total: 0,
    },
    isLoading: false,
  } as ITinkoffOperationsState,
  reducers: (create) => ({
    fetchTinkoffOperations: create.asyncThunk(
      async (params: IFetchOwnerOperationsParams & RequestParamsWithPagination) => {
        const { data } = await instance.get('/owners/tinkoff-operations', {
          params,
        });

        return data;
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.operations = action.payload.data;
          state.pagination = action.payload.meta;
        },
        rejected: (state) => {
          state.isLoading = false;
        },
      }
    ),
  }),
});

export const { fetchTinkoffOperations } = tinkoffOperationsSlice.actions;
export const tinkoffOperationsReducer = tinkoffOperationsSlice.reducer;
