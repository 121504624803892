import { ColumnsType } from 'antd/lib/table';
import { DEFAULT_DATETIME_STRING_FORMAT } from 'constants/dates';
import { OwnerTinkoffOperationTypeEnum, ownerTinkoffOperationTypeToTitle } from 'modules/owners/types/type.enum';
import { IOwnerTinkoffOperation } from 'modules/owners/types/ownerTinkoffOperation.interface';
import dayjs from 'dayjs';

export const columns: ColumnsType<IOwnerTinkoffOperation> = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Владелец',
    key: 'ownerId',
    dataIndex: 'ownerId',
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Сумма',
    key: 'amount',
    render: (_, { amount }) => amount?.toLocaleString('ru-RU') ?? '',
  },
  {
    title: 'Баланс',
    key: 'balance',
    render: (_, { balance }) => balance?.toLocaleString('ru-RU') ?? '',
  },
  {
    title: 'Коммиссия',
    key: 'commission',
    render: (_, { commission }) => commission?.toLocaleString('ru-RU') ?? '',
  },
  {
    title: 'Дата и время операции по банку',
    key: 'operationDate',
    dataIndex: 'operationDate',
    render: (value: string) => dayjs(value).format(DEFAULT_DATETIME_STRING_FORMAT),
  },
  {
    title: 'Доп. информация',
    key: 'description',
    dataIndex: 'description',
  },
  {
    title: 'Тип',
    key: 'type',
    dataIndex: 'type',
    render: (value: OwnerTinkoffOperationTypeEnum) => ownerTinkoffOperationTypeToTitle[value],
  },
  {
    title: 'Номер карты владельца',
    key: 'ownerCard',
    dataIndex: 'ownerCard',
  },
  {
    title: 'Номер карты клиента',
    key: 'customerCard',
    dataIndex: 'customerCard',
  },
];
