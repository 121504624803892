export enum OwnerTinkoffOperationTypeEnum {
  TRANSFER = 'TRANSFER',
  INCOME = 'INCOME',
  PAY = 'PAY',
  CHARGE = 'CHARGE',
  CASH = 'CASH',
  MOVEMENT = 'MOVEMENT',
}

export const ownerTinkoffOperationTypeToTitle = {
  [OwnerTinkoffOperationTypeEnum.TRANSFER]: 'Перевод',
  [OwnerTinkoffOperationTypeEnum.INCOME]: 'Поступление',
  [OwnerTinkoffOperationTypeEnum.PAY]: 'Оплата',
  [OwnerTinkoffOperationTypeEnum.CHARGE]: 'Списание',
  [OwnerTinkoffOperationTypeEnum.CASH]: 'Наличные',
  [OwnerTinkoffOperationTypeEnum.MOVEMENT]: 'Движение средств',
};
