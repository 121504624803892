import { useEffect, useRef, useState } from 'react';
import { URLSearchParamsInit, useLocation, useSearchParams } from 'react-router-dom';

import { Location } from 'history';

/**
 * Хук для расширения функций фильтров:
 *  - параметры добавляются в URL, что позволяет передавать состояние фильтров через ссылку
 *  - фильтры сохраняются в localStorage
 *
 * @author Рябущиц Иван <telegram: @ivangeli0>
 */
export function useMemoFilters<T>(isChangeUrl = true, customKey?: string) {
  const onInitialMount = useRef(true);
  const location: Location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const [memoFilters, setMemoFilters] = useState<T>(getFilters<T>(location, isChangeUrl));

  useEffect(() => {
    if (onInitialMount.current) {
      onInitialMount.current = false;
      return;
    }
    localStorage.setItem(makeKeyForLS(location, customKey), JSON.stringify(memoFilters));
    if (isChangeUrl) {
      setSearchParams(clearFalsy<T>(memoFilters) as URLSearchParamsInit);
    }
  }, [memoFilters]);

  return { memoFilters, setMemoFilters };
}

/**
 * Формирование ключа для LocalStorage на основе роута.
 *
 * @author Рябущиц Иван <telegram: @ivangeli0>
 */
function makeKeyForLS(location: Location, customKey?: string): string {
  const prefix = 'memo_filters_for_';

  // /owners/transactions/  => owners-transactions
  const currentRouteKey: string = customKey || location.pathname.replaceAll(/(^\W|\W$)/g, '').replaceAll('/', '-');

  return prefix + currentRouteKey;
}

/**
 * Получение фильтров c помощью извлечения из гет-параметров URL или из LocalStorage.
 *
 * @author Рябущиц Иван <telegram: @ivangeli0>
 */
function getFilters<T>(location: Location, isChangeUrl = true): T {
  if ('' !== location.search && isChangeUrl) {
    // URL-параметры в приоритете
    const params = {} as Record<string, unknown[]>;
    const urlParams = new URLSearchParams(location.search);
    for (const [key, value] of urlParams.entries()) {
      if (key.endsWith('[]')) {
        const correctKey = key.replaceAll(/\[\]/g, '');
        if (!params[correctKey]) {
          params[correctKey] = [value];
        } else {
          const arrParams = params[correctKey];
          arrParams.push(value);
          params[correctKey] = arrParams;
        }

        continue;
      }
      params[key] = isJsonString(value) ? JSON.parse(value) : value;
    }

    return params as T;
  } else if (null !== localStorage.getItem(makeKeyForLS(location))) {
    return JSON.parse(<string>localStorage.getItem(makeKeyForLS(location)));
  } else {
    return {} as T;
  }
}

/**
 * Очистка значений фильтров от falsy-values.
 *
 * @author Рябущиц Иван <telegram: @ivangeli0>
 */
function clearFalsy<F>(filters: F): F {
  const result = Object.assign({}, filters) as Record<string, unknown>;
  for (const [key, value] of Object.entries(result)) {
    if (null === value || undefined === value || '' === value) {
      delete result[key];
    }
    if (Array.isArray(value)) {
      result[key] = JSON.stringify(value);
    }
  }

  return result as F;
}

/**
 * Проверка на то, JSON ли строка
 *
 * @author Рассохин Алексей <telegram: @alras63>
 */
function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
